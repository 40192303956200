@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@media only screen and (max-width: 1200px) {
	.material-card {
		margin: auto;
		width: 90%;
	}
}

@media only screen and (min-width: 1200px) {
	.material-card {
		margin: auto;
		width: 50%;
	}
}

.infoicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Finfo.svg?alt=media&token=82bddfd6-2a77-4bb0-ad55-21728a95b094');
	background-size: cover;
}

.moneyicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fmoney.svg?alt=media&token=fa2156fd-5b0b-4c39-9f32-3f97d5119798');
	background-size: cover;
}

.paymenticon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fpayment.svg?alt=media&token=fdfb3fa1-5d6d-495d-bee4-f72c54d7ba1b');
	background-size: cover;
}

.transportationicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Ftransportation.svg?alt=media&token=4cc92604-18ab-4990-9e80-1b7e9b384348');
	background-size: cover;
}

.checkedicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fcheck.svg?alt=media&token=fc6189d9-ca4c-4fc5-8df4-0e911677d959');
	background-size: cover;
}

.bugicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fbug.svg?alt=media&token=67774aae-34c3-433a-8845-9c2951a4aa59');
	background-size: cover;
}

.polymericon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fpolymer.svg?alt=media&token=4b397847-8869-4980-8553-3e437e62cfe5');
	background-size: cover;
}

.timeicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Ftime.svg?alt=media&token=dfe3b2b5-0cd1-4eba-a4d5-8c4529fd6c02');
	background-size: cover;
}

.memoryicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fmemory.svg?alt=media&token=d1cde882-4549-4482-8efa-b752e339ef9b');
	background-size: cover;
}

.sportsicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fsports.svg?alt=media&token=f88ab757-e419-4c93-ae79-a8eb6867bb9d');
	background-size: cover;
}

.warningicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fwarning.svg?alt=media&token=e3917b1b-3dcc-4a0c-94f3-e8d507cbb22a');
	background-size: cover;
}

.cakeicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fcake.svg?alt=media&token=7d947733-a81a-41a4-9f50-89553a6a18ff');
	background-size: cover;
}

.cameraicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fcamera.svg?alt=media&token=37d3b668-b257-4d64-82c2-a746f9ff4da5');
	background-size: cover;
}

.workicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fwork.svg?alt=media&token=64023f32-3047-4f80-b9c7-3d923953be7c');
	background-size: cover;
}

.peopleicon {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Fpeople.svg?alt=media&token=e23c5dcb-cc38-414d-8422-a0bfcffa0882');
	background-size: cover;
}

.offer {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FmaterialIcons%2Foffer.svg?alt=media&token=bc228dcf-b3ff-41a1-afbb-228c60650f35');
	background-size: cover;
}
