.localhost {
  background-color: #4f2372;
}

.teasley {
  background-color: #4f2372;
}

.cms {
  background-color: blue;
}

.nickajack {
  background-color: #ff0000;
}

.teasleyheaderimage {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FteasleyIcon.png?alt=media&token=b85b381d-16bc-4bb7-8261-a8799b1bf1c0");
  background-size: cover;
}

.cmsheaderimage {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FspartanupIcon.png?alt=media&token=5171c433-9e28-4887-9a4a-8fa024c548b6");
  background-size: cover;
}

.nickajackheaderimage {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FneslogoIcon.png?alt=media&token=25404f4a-ec11-459c-85b0-84d4323f8388");
  background-size: cover;
}

.yongsaheaderimage {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2FyongsaIcon.png?alt=media&token=0772dc4b-de00-46f8-9e1b-68c0d2efb1bd");
  background-size: cover;
}
