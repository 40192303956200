@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss created using  */
$md-yongsa: (
	50: #fff8e1,
	100: #ffecb5,
	200: #ffe083,
	300: #ffd451,
	400: #ffca2c,
	500: #ffc107,
	600: #ffbb06,
	700: #ffb305,
	800: #ffab04,
	900: #ff9e02,
	A100: #ffffff,
	A200: #fffaf2,
	A400: #ffe4bf,
	A700: #ffd9a6,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($md-yongsa);
$candy-app-accent: mat-palette($mat-grey, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);
